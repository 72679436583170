<template>
  <div v-if="isReady">
    <b-row>
      <b-col md="6">
        <div class="d-flex">
          <b class="my-auto">{{ capitalize($t('totalWon:')) }}</b>
          <b class="mx-2 my-auto">{{ opportunitiesQuality.global.Period['Total won'] }}</b>
          <indicator-evolution
              :indicator="opportunitiesQuality.global.Evolution['Total won']"
              class="my-auto"
          />
        </div>

        <div class="d-flex">
          <b class="my-auto">{{ capitalize($t('averageWon:')) }}</b>
          <b class="mx-2 my-auto">{{ opportunitiesQuality.global.Period['Average won'] }}</b>
          <indicator-evolution
              :indicator="opportunitiesQuality.global.Evolution['Average won']"
              class="my-auto"
          />
        </div>
      </b-col>

      <b-col md="6">
        <div class="d-flex">
          <b class="my-auto">{{ capitalize($t('totalLost:')) }}</b>
          <b class="mx-2 my-auto">{{ opportunitiesQuality.global.Period['Total lost'] }}</b>
          <indicator-evolution
              :indicator="opportunitiesQuality.global.Evolution['Total lost']"
              class="my-auto"
          />
        </div>

        <div class="d-flex">
          <b class="my-auto">{{ capitalize($t('averageLost:')) }}</b>
          <b class="mx-2 my-auto">{{ opportunitiesQuality.global.Period['Average lost'] }}</b>
          <indicator-evolution
              :indicator="opportunitiesQuality.global.Evolution['Average lost']"
              class="my-auto"
          />
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col md="12">
        <vue-apex-charts
            type="bar"
            height="230"
            :options="chartOptions"
            :series="series"
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../../../../utils/filter'
import { isEmptyObject }        from '../../../../../../utils/utils'

import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'

import IndicatorEvolution from '../../../../../../components/indicatorEvolution/IndicatorEvolution.vue'
import i18n               from '../../../../../../libs/i18n'

export default {
  components: {
    IndicatorEvolution,
    VueApexCharts
  },
  props: {
    opportunitiesQuality: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !(Object.keys(props.opportunitiesQuality.chart).length === 0 && props.opportunitiesQuality.chart.constructor === Object) && !isEmptyObject(props.opportunitiesQuality.chart)
    })

    const series = computed(() => {
      if (isReady.value) {
        let successes = []
        let losts = []
        Object.values(props.opportunitiesQuality.chart).forEach(item => {
          // console.log(item)
          successes.push(item.Success)
          losts.push(-item.Lost)
        })

        return [
          {
            name: capitalize(i18n.t('lost')),
            data: losts
          },
          {
            name: capitalize(i18n.t('success')),
            data: successes
          },
        ]
      } else {
        return []
      }
    })

    const chartOptions = computed(() => {
      let chartOptions = {
        chart: {
          stacked: true,
          type: 'bar',
          toolbar: { show: false },
        },
        grid: {
          padding: {
            top: -20,
            bottom: -10,
          },
          yaxis: {
            lines: { show: false },
          },
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.86rem',
            },
            formatter: function (val) {
              return capitalize(moment(val).format('MMMM YYYY'))
            }
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#ea5455", "#28c76f"],
        tooltip: {
          shared: false,
          x: {
            formatter: function (val) {
              return capitalize(moment(val).format('MMMM YYYY'))
            }
          },
          y: {
            formatter: function (val) {
              return Math.abs(val)
            }
          }
        },
        plotOptions: {
          bar: {
            columnWidth: 10,
            borderRadius: 5,
            borderRadiusWhenStacked: 'all'
          },
          distributed: true,
        },
        yaxis: {
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.86rem',
            },
            formatter: function (val) {
              return Math.abs(val)
            }
          },
        },
      }

      if (isReady.value) {
        chartOptions.xaxis.categories = Object.keys(props.opportunitiesQuality.chart)
      }

      return chartOptions
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      isReady,
      series,
      chartOptions,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>