<template>
  <div>
    <b-overlay
        :show="!isReady"
        rounded="sm"
        bg-color="white"
    >
      <b-card
          v-if="isReady"
          class="text-center"
      >
        <b-avatar
            class="mb-1"
            variant="light-secondary"
            size="45"
        >
          <icon
              icon="bell"
              size="2x"
          />
        </b-avatar>
        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder text-truncate">
            {{ capitalize($t('averageBasket')) }}
          </h2>
          <span>{{ currency(datas.total, true) }}</span>
        </div>

        <div class="mt-2">
          <indicator-evolution-pill
              :indicator="datas.evolution"
          />
        </div>
      </b-card>
      <b-card v-else>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { isEmptyObject }        from '../../../../../utils/utils'
import { capitalize, currency } from '../../../../../utils/filter'
import IndicatorEvolution       from '../../../../../components/indicatorEvolution/IndicatorEvolution.vue'
import IndicatorEvolutionPill   from '../../../../../components/indicatorEvolution/IndicatorEvolutionPill.vue'

export default {
  components: { IndicatorEvolutionPill, IndicatorEvolution },
  props: {
    datas: {
      type: Object,
      default: () => {}
    },
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !isEmptyObject(props.datas)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      isReady,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { currency, capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>