import { render, staticRenderFns } from "./OpportunitiesMainIndicatorButton.vue?vue&type=template&id=ec09f962&scoped=true&"
import script from "./OpportunitiesMainIndicatorButton.vue?vue&type=script&lang=js&"
export * from "./OpportunitiesMainIndicatorButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec09f962",
  null
  
)

export default component.exports