<template>
  <div>
    <b-card no-body>
      <b-overlay
          :show="!isReady"
          rounded="sm"
          class="w-100"
          bg-color="white"
      >
        <b-card-body
            v-if="isReady"
            class="d-flex justify-content-between align-items-center"
        >

          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              {{ datas.late }}
            </h2>
            <span>{{ capitalize($t('workFlosWithoutActions')) }}</span>
          </div>
          <b-avatar
              variant="light-warning"
              size="45"
          >
            <icon
                icon="exclamation-triangle"
                size="2x"
            />
          </b-avatar>
        </b-card-body>


        <b-card-body v-else>
        </b-card-body>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../../../utils/filter'
import { isEmptyObject }        from '../../../../../utils/utils'

export default {
  components: {},
  props: {
    datas: {
      type: Object,
      default: () => {}
    },
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !isEmptyObject(props.datas)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed
      isReady,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>