<template>
  <div>
    <b-row>
      <filter-details
          :filters.sync="localFilters"
          :available-filters="availableFilters"
          class="mt-50"
      />
    </b-row>

    <b-row>
      <b-col md="12">
        <main-indicator
            :datas="mainIndicator"
            :selected-indicator-view.sync="selectedIndicatorView"
        />
      </b-col>
    </b-row>

    <main-indicator-view
        v-if="selectedIndicatorView == ''"
        ref="mainIndicatorView"
        :local-filters="localFilters"
    />

    <index-sales
        v-if="selectedIndicatorView == 'sales'"
        ref="salesView"
        :local-filters="localFilters"
    />

    <index-opportunities
        v-if="selectedIndicatorView == 'opportunities'"
        ref="opportunitiesView"
        :local-filters="localFilters"
    />

  </div>
</template>

<script>
import { ref, computed, watch }                                           from '@vue/composition-api'
import { getCurrentFinancialYear, getPreviousFinancialYear, getUserData } from '../../../utils/utils'
import { capitalize }                                                     from '../../../utils/filter'

import moment from 'moment'
import store  from '../../../store'
import i18n   from '../../../libs/i18n'

import EstimatedTurnover  from './components/main/EstimatedTurnover.vue'
import LateWorkFlos       from './components/main/LateWorkFlos.vue'
import CustomerWorkFlos   from './components/main/CustomerWorkFlos.vue'
import FilterDetails      from '../../../components/base3/header/FilterDetails.vue'
import Orders             from './components/main/Orders.vue'
import MainIndicator      from './components/MainIndicator.vue'
import AverageBasket      from './components/main/AverageBasket.vue'
import TimeToCompletion   from './components/main/TimeToCompletion.vue'
import CompletionRate     from './components/main/CompletionRate.vue'
import MainIndicatorView  from './components/MainIndicatorView.vue'
import IndexSales         from './components/sales/IndexSales.vue'
import IndexOpportunities from './components/opportunities/IndexOpportunities.vue'

import useAPI  from '../../../utils/useAPI'
import ability from '../../../libs/acl/ability'

export default {
  components: {
    TimeToCompletion,
    AverageBasket,
    MainIndicator,
    Orders,
    FilterDetails,
    CustomerWorkFlos,
    LateWorkFlos,
    EstimatedTurnover,
    CompletionRate,
    MainIndicatorView,
    IndexSales,
    IndexOpportunities
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const selectedIndicatorView = ref('')
    const mainIndicatorView = ref()
    const salesView = ref()
    const opportunitiesView = ref()

    const mainIndicator = ref({})

    // const durationStart = ref(getCurrentFinancialYear().start)
    const durationStart = ref('2023-09-01')
    const durationEnd = ref(moment().format('YYYY-MM-DD'))
    // const comparisonDurationStart = ref(getPreviousFinancialYear().start)
    const comparisonDurationStart = ref('2022-09-01')
    const comparisonDurationEnd = ref(moment().subtract(1, 'year').format('YYYY-MM-DD'))
    const localFilters = ref([
      {
        sectionLogicOperator: 'AND',
        sectionFilters: [
          {
            logicOperator: 'AND',
            field: '_dashboardPeriod',
            operator: 'between',
            // value: ['2023-07-01', '2024-06-30']
            value: [durationStart.value, durationEnd.value],
            _sectionOperatorDisabled: true,
            _fieldDisabled: true,
            _operatorDisabled: true,
            _allowDelete: false,
            _isInputValueDisabled: false
          }
        ]
      },
      {
        sectionLogicOperator: 'AND',
        sectionFilters: [
          {
            logicOperator: 'AND',
            field: '_dashboardComparativePeriod',
            operator: 'between',
            // value: ['2022-07-01', '2023-06-30']
            value: [comparisonDurationStart.value, comparisonDurationEnd.value],
            _sectionOperatorDisabled: true,
            _fieldDisabled: true,
            _operatorDisabled: true,
            _allowDelete: false,
            _isInputValueDisabled: false
          }
        ]
      },
      // {
      //   sectionLogicOperator: 'AND',
      //   sectionFilters: [
      //     {
      //       logicOperator: 'AND',
      //       field: '_dashboardUser',
      //       operator: 'equal',
      //       value: getUserData().id,
      //       _sectionOperatorDisabled: true,
      //       _fieldDisabled: true,
      //       _operatorDisabled: true,
      //       _allowDelete: ability.can('manage', 'all'),
      //       _isInputValueDisabled: !ability.can('manage', 'all')
      //     }
      //   ]
      // }
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const availableFilters = computed(() => {
      return [
        {
          field: '_dashboardPeriod',
          i18n: capitalize(i18n.t('period')),
          subFields: [],
          type: 'date',
          _sectionOperatorDisabled: true,
          _fieldDisabled: true,
          _operatorDisabled: true,
          _allowDelete: false,
          _isInputValueDisabled: false
        },
        {
          field: '_dashboardComparativePeriod',
          i18n: capitalize(i18n.t('comparison')),
          subFields: [],
          type: 'date',
          _sectionOperatorDisabled: true,
          _fieldDisabled: true,
          _operatorDisabled: true,
          _allowDelete: false,
          _isInputValueDisabled: false
        },
        {
          field: '_dashboardUser',
          i18n: capitalize(i18n.tc('employee')),
          subFields: [],
          type: 'employee',
          _sectionOperatorDisabled: true,
          _fieldDisabled: true,
          _operatorDisabled: true,
          _allowDelete: ability.can('manage', 'all'),
          _isInputValueDisabled: !ability.can('manage', 'all')
        },
        {
          field: 'typeOfSale',
          i18n: capitalize(i18n.t('typeOfSale')),
          subFields: [],
          type: 'select',
          options: [
            {
              i18n: "Vente directe",
              value: 'Vente directe'
            },
            {
              i18n: "Vente indirecte",
              value: 'Vente indirecte'
            },
            {
              i18n: "Autre",
              value: 'Autre'
            },
          ],
          _sectionOperatorDisabled: true,
          _fieldDisabled: false,
          _operatorDisabled: true
        },
        {
          field: 'typeOfPurchase',
          i18n: capitalize(i18n.t('typeOfPurchase')),
          subFields: [],
          type: 'select',
          options: [
            {
              i18n: "Premier achat",
              value: 'Premier achat'
            },
            {
              i18n: capitalize(i18n.t('Achat récurrent')),
              value: 'Achat récurrent'
            },
            {
              i18n: capitalize(i18n.t('Achat post location')),
              value: 'Achat post location'
            },
          ],
          _sectionOperatorDisabled: true,
          _fieldDisabled: false,
          _operatorDisabled: true
        },
        {
          field: 'sourceIncomingRequest',
          i18n: capitalize(i18n.t('sourceIncomingRequest')),
          subFields: [],
          type: 'select',
          options: [
            {
              value: 'internet research',
              i18n: capitalize(i18n.t('internetResearch')),
            },
            {
              value: 'social networking',
              i18n: capitalize(i18n.t('socialNetworking')),
            },
            {
              value: 'press',
              i18n: capitalize(i18n.t('press/media')),
            },
            {
              value: 'networks',
              i18n: capitalize(i18n.tc('network',2)),
            },
            {
              value: 'newsletter',
              i18n: capitalize(i18n.t('newsletter')),
            },
            {
              value: 'event',
              i18n: capitalize(i18n.tc('event')),
            },
            {
              value: 'farming',
              i18n: capitalize(i18n.tc('farming')),
            },
          ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
          _sectionOperatorDisabled: true,
          _fieldDisabled: false,
          _operatorDisabled: true
        },
        {
          field: 'state',
          i18n: capitalize(i18n.t('state')),
          subFields: [],
          type: 'select',
          options: [
            {
              i18n: capitalize(i18n.t('inProgress')),
              value: 'in progress'
            },
            {
              i18n: capitalize(i18n.t('success')),
              value: 'success'
            },
            {
              i18n: capitalize(i18n.t('lost')),
              value: 'lost'
            }
          ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
          _sectionOperatorDisabled: true,
          _fieldDisabled: false,
          _operatorDisabled: true
        },
        {
          field: 'businessSector',
          i18n: capitalize(i18n.t('businessSector')),
          subFields: [],
          type: 'select',
          options: [
            {
              value: 'industry',
              i18n: capitalize(i18n.t('industry')),
            },
            {
              value: 'maintenance/servicing',
              i18n: capitalize(i18n.t('maintenance/servicing')),
            },
            {
              value: 'agriculture/agri-food',
              i18n: capitalize(i18n.t('agriculture/agri-food')),
            },
            {
              value: 'public works buildings',
              i18n: capitalize(i18n.t('public works buildings')),
            },
            {
              value: 'logistics',
              i18n: capitalize(i18n.t('logistics')),
            },
            {
              value: 'health',
              i18n: capitalize(i18n.t('health')),
            },
            {
              value: 'other',
              i18n: capitalize(i18n.t('other')),
            },
          ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
          _sectionOperatorDisabled: true,
          _fieldDisabled: false,
          _operatorDisabled: true
        },
      ]
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(localFilters, val => {
      // console.log(val)
      resetRequests()
      mainIndicatorRequest()

      if (selectedIndicatorView.value == '') {
        mainIndicatorView.value.performRequests(val)
      } else if (selectedIndicatorView.value == 'sales') {
        salesView.value.performRequests(val)
      } else if (selectedIndicatorView.value == 'opportunities') {
        opportunitiesView.value.performRequests(val)
      }

    }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchAutocompleteEmployees } = useAPI()

    const mainIndicatorRequest = () => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'mainIndicator',
        filters: localFilters.value
      })
          .then(response => {
            mainIndicator.value = response.data.results
          })
    }

    const resetRequests = () => {
      mainIndicator.value = {}
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchAutocompleteEmployees()
    mainIndicatorRequest()

    return {
      // Components

      // Data
      selectedIndicatorView,
      mainIndicatorView,
      salesView,
      opportunitiesView,
      mainIndicator,
      localFilters,

      // Computed
      availableFilters,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>