<template>
  <div>
    <b-row>

      <b-col md="8">
        <b-card :title="capitalize($t('salesIndicators'))">
          <dashed-buttons
              :buttons="dashedButtons"
              :selected.sync="selectedDashedButton"
              :allow-add="false"
          />

          <orders-main-chart
              v-if="selectedDashedButton == 'order billing status'"
              :sales-orders-chart="salesOrdersChart"

          />

          <type-of-purchase-orders-main-chart
              v-if="selectedDashedButton == 'type of purchases orders'"
              :sales-type-of-purchase-orders-chart="typeOfPurchasesOrdersChart"

          />

          <pipe-forecast-main-chart
              v-if="selectedDashedButton == 'pipe forecast'"
              :pipe-forecast-chart="pipeForecastChart"
          />

        </b-card>
      </b-col>


      <b-col md="4">
        <type-of-purchase-rate-chart
            v-if="selectedDashedButton == 'type of purchases orders'"
            :sales-type-of-purchase-rate-chart="typeOfPurchasesRateChart"
            :is-type-of-purchases-rate-amount-type.sync="isTypeOfPurchasesRateAmountType"
        />
      </b-col>

    </b-row>

    <averages-baskets
        v-if="selectedDashedButton == 'type of purchases orders'"
        :averages-baskets="averagesBaskets"
    />

    <b-row>
      <b-col md="12">
        <sales-list
            :sales-list="salesList"
            :list-entity-type="listEntityType"
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../../../utils/filter'

import store from '../../../../../store'

import DashedButtons                 from '../../../../../components/dashedButtons/DashedButtons.vue'
import OrdersMainChart               from './orders/OrdersMainChart.vue'
import TypeOfPurchaseOrdersMainChart from './typeOfPurchaseOrders/TypeOfPurchaseOrdersMainChart.vue'
import TypeOfPurchaseRateChart       from './typeOfPurchaseOrders/TypeOfPurchaseRateChart.vue'
import PipeForecastMainChart         from './pipeForecast/PipeForecastMainChart.vue'
import AveragesBaskets               from './typeOfPurchaseOrders/AveragesBaskets.vue'
import SalesList                     from './SalesList.vue'

export default {
  components: {
    SalesList,
    AveragesBaskets,
    DashedButtons,
    OrdersMainChart,
    TypeOfPurchaseOrdersMainChart,
    TypeOfPurchaseRateChart,
    PipeForecastMainChart
  },
  props: {
    localFilters: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const dashedButtons = ref([
      {
        icon: 'shopping-cart',
        value: 'order billing status'
      },
      {
        icon: 'shopping-cart',
        value: 'type of purchases orders'
      },
      {
        icon: 'chart-bar',
        value: 'pipe forecast'
      },
      {
        icon: 'chart-pie',
        value: 'average basket'
      },
    ])
    const selectedDashedButton = ref('order billing status')
    const salesOrdersChart = ref({
      chart: {},
      list: [],
    })
    const typeOfPurchasesOrdersChart = ref({
      chart: {},
      list: [],
    })
    const pipeForecastChart = ref({
      chart: {},
      list: [],
    })
    const typeOfPurchasesRateChart = ref({
      chart: {}
    })
    const isTypeOfPurchasesRateAmountType = ref(false)
    const averagesBaskets = ref({})
    const listEntityType = ref('offer')

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const salesList = computed(() => {
      if (selectedDashedButton.value == 'order billing status') {
        listEntityType.value = 'offer'
        return salesOrdersChart.value.list
      } else if (selectedDashedButton.value == 'type of purchases orders') {
        listEntityType.value = 'offer'
        return typeOfPurchasesOrdersChart.value.list
      } else if (selectedDashedButton.value == 'pipe forecast') {
        listEntityType.value = 'workFlo'
        return pipeForecastChart.value.list
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(selectedDashedButton, val => {
      performRequests(props.localFilters)
    })

    watch(isTypeOfPurchasesRateAmountType, val => {
      typeOfPurchasesRateChart.value = {
        chart: {}
      }
      if (val) {
        salesTypeOfPurchaseRateAmountChartRequest(props.localFilters)
      } else {
        salesTypeOfPurchaseRateNumberChartRequest(props.localFilters)
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetRequests = () => {
      salesOrdersChart.value = {
        chart: {},
        list: [],
      }
      typeOfPurchasesOrdersChart.value = {
        chart: {},
        list: [],
      }
      pipeForecastChart.value = {
        chart: {},
        list: [],
      }
      typeOfPurchasesRateChart.value = {
        chart: {}
      }
      averagesBaskets.value = {}
    }

    const performRequests = (updatedFilters) => {
      resetRequests()

      if (selectedDashedButton.value == 'order billing status') {
        salesOrdersChartRequest(updatedFilters)
      } else if (selectedDashedButton.value == 'type of purchases orders') {
        salesTypeOfPurchaseOrdersChartRequest(updatedFilters)

        if (isTypeOfPurchasesRateAmountType.value) {
          salesTypeOfPurchaseRateAmountChartRequest(updatedFilters)
        } else {
          salesTypeOfPurchaseRateNumberChartRequest(updatedFilters)
        }
        salesAveragesBasketsRequest(updatedFilters)

      } else if (selectedDashedButton.value == 'pipe forecast') {
        salesPipeForecastChartRequest(updatedFilters)
      }
    }

    const salesOrdersChartRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'sales_orders',
        filters: updatedFilters
      })
          .then(response => {
            salesOrdersChart.value = response.data.results
          })
    }

    const salesTypeOfPurchaseOrdersChartRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'sales_typeOfPurchaseOrders',
        filters: updatedFilters
      })
          .then(response => {
            typeOfPurchasesOrdersChart.value = response.data.results
          })
    }

    const salesPipeForecastChartRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'sales_pipeForecast',
        filters: updatedFilters
      })
          .then(response => {
            pipeForecastChart.value = response.data.results
          })
    }

    const salesTypeOfPurchaseRateNumberChartRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'sales_typeOfPurchaseRateNumber',
        filters: updatedFilters
      })
          .then(response => {
            typeOfPurchasesRateChart.value = response.data.results
          })
    }

    const salesTypeOfPurchaseRateAmountChartRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'sales_typeOfPurchaseRateAmount',
        filters: updatedFilters
      })
          .then(response => {
            typeOfPurchasesRateChart.value = response.data.results
          })
    }

    const salesAveragesBasketsRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'sales_averagesBaskets',
        filters: updatedFilters
      })
          .then(response => {
            averagesBaskets.value = response.data.results
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    performRequests(props.localFilters)

    return {
      // Components

      // Data
      dashedButtons,
      selectedDashedButton,
      salesOrdersChart,
      typeOfPurchasesOrdersChart,
      pipeForecastChart,
      typeOfPurchasesRateChart,
      isTypeOfPurchasesRateAmountType,
      averagesBaskets,
      listEntityType,

      // Computed
      salesList,

      // Methods
      performRequests,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>