<template>
  <div>
    <b-badge
        pill
        :variant="badgeColor"
        class="my-auto"
    >
      <span v-if="isCurrency">{{ displayNumberWithSymbol(currency(indicator, true)) }}</span>
      <span v-else-if="isPercent">{{ displayNumberWithSymbol(percent(indicator, 0)) }}</span>
      <span v-else>{{ displayNumberWithSymbol(indicator) }}</span>
    </b-badge>
  </div>
</template>

<script>
import { ref, computed, watch }                       from '@vue/composition-api'
import { currency, displayNumberWithSymbol, percent } from '../../utils/filter'

export default {
  components: {},
  props: {
    indicator: {
      type: Number,
      default: 0
    },
    isPercent: {
      type: Boolean,
      default: true
    },
    isCurrency: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const badgeColor = computed(() => {
      return props.indicator > 0 ? 'light-success' : 'light-danger'
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      badgeColor,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { percent, currency, displayNumberWithSymbol },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>