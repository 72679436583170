<template>
  <div>
    <div class="d-flex justify-content-center">
      <div class="my-auto mr-50 w-full text-truncate">{{ capitalize($t('numberOfOrders')) }}</div>
      <switch-input
          :model.sync="isTypeOfPurchasesOrderAmountType"
          class="my-auto mb-2"
      />
      <div class="my-auto ml-50 w-full text-truncate">{{ capitalize($t('salesVolume')) }}</div>
    </div>

    <vue-apex-charts
        type="bar"
        height="400"
        :options="chartOptions"
        :series="series"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../../../../utils/filter'

import VueApexCharts from 'vue-apexcharts'

import i18n   from '../../../../../../libs/i18n'
import moment from 'moment'
import SwitchInput from '../../../../../../components/input/Switch.vue'

export default {
  components: {
    SwitchInput,
    VueApexCharts
  },
  props: {
    salesTypeOfPurchaseOrdersChart: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isTypeOfPurchasesOrderAmountType = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !(Object.keys(props.salesTypeOfPurchaseOrdersChart.chart).length === 0 && props.salesTypeOfPurchaseOrdersChart.chart.constructor === Object)
    })

    const orderedAchatMalRenseigneByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.salesTypeOfPurchaseOrdersChart.chart).forEach(monthValArray => {
          if (isTypeOfPurchasesOrderAmountType.value) {
            output.push(monthValArray.orderedAchatMalRenseigneAmount)
          } else {
            output.push(monthValArray.orderedAchatMalRenseigne)
          }
        })
      }

      return output
    })

    const orderedAchatPostLocationByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.salesTypeOfPurchaseOrdersChart.chart).forEach(monthValArray => {
          if (isTypeOfPurchasesOrderAmountType.value) {
            output.push(monthValArray.orderedAchatPostLocationAmount)
          } else {
            output.push(monthValArray.orderedAchatPostLocation)
          }
        })
      }

      return output
    })

    const orderedAchatRecurrentByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.salesTypeOfPurchaseOrdersChart.chart).forEach(monthValArray => {
          if (isTypeOfPurchasesOrderAmountType.value) {
            output.push(monthValArray.orderedAchatRecurrentAmount)
          } else {
            output.push(monthValArray.orderedAchatRecurrent)
          }
        })
      }

      return output
    })

    const orderedPremierAchatByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.salesTypeOfPurchaseOrdersChart.chart).forEach(monthValArray => {
          if (isTypeOfPurchasesOrderAmountType.value) {
            output.push(monthValArray.orderedPremierAchatAmount)
          } else {
            output.push(monthValArray.orderedPremierAchat)
          }
        })
      }

      return output
    })

    const months = computed(() => {
      if (isReady.value) {
        return Object.keys(props.salesTypeOfPurchaseOrdersChart.chart)
      } else {
        return []
      }
    })

    const series = computed(() => {
      return [
        {
          name: "Achat mal renseigné",
          data: orderedAchatMalRenseigneByMonths.value,
        },
        {
          name: "Premier achat",
          data: orderedPremierAchatByMonths.value,
        },
        {
          name: "Achat récurrent",
          data: orderedAchatRecurrentByMonths.value,
        },
        {
          name: "Achat post location",
          data: orderedAchatPostLocationByMonths.value,
        },
      ]
    })

    const chartOptions = computed(() => {
      return {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        colors: ['#ff4560', '#008ffb', '#feb019', '#00e396'],
        plotOptions: {
          bar: {
            columnWidth: '20',
            borderRadius: 5,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: 'top',
          fontSize: '14px',
          fontFamily: 'Montserrat',
          horizontalAlign: 'left',
        },
        stroke: {
          show: true,
          colors: ['transparent'],
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: months.value,
          labels: {
            formatter: (val) => {
              return capitalize(moment(val).format('MMMM YYYY'))
            }
          }
        },
        yaxis: {
          // opposite: isRtl,
          labels: {
            formatter: (val) => {
              if (isTypeOfPurchasesOrderAmountType.value) {
                return currency(val, true)
              } else {
                return val
              }
            }
          }
        },
        fill: {
          opacity: 1,
        },
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isTypeOfPurchasesOrderAmountType,

      // Computed
      series,
      chartOptions,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>