<template>
  <div>
    <b-card>
      <b-overlay
          :show="!isReady"
          rounded="sm"
      >
        <b-row v-if="isReady">
          <b-col md="6">
            <h4>{{ capitalize($tc('order', 2)) }}</h4>
            <h3>{{ currency(datas.total, true) }}</h3>
          </b-col>
          <b-col
              md="6"
              class="text-right"
          >
            <indicator-evolution
                :indicator="datas.evolution"
            />
          </b-col>
        </b-row>

        <b-row
            v-if="isReady"
            class="mt-2"
        >

          <b-col md="5">
            <span
                class="rounded"
                style="padding: 0.3rem; background-color: rgba(0,207,232,0.1);"
            >
              <icon
                  icon="shopping-cart"
                  class="text-info "
              />
            </span>
            <span>&nbsp; {{ capitalize($t('ordered')) }}</span>

            <h5 class="mt-1">
              {{ percent(datas.percentOrderedNotInvoiced) }}
            </h5>
            <div>
              {{ currency(datas.preTaxAmountOrderedNotInvoiced, true) }}
            </div>
          </b-col>

          <b-col md="2">
            <div class="d-flex flex-column align-center justify-center h-100">
              <hr
                  aria-orientation="vertical"
                  role="separator"
                  class="v-divider v-divider--vertical mx-auto"
                  style="margin-top: 0.5rem; margin-bottom: 0.5rem;"
              >
              <div class="mx-auto">
                <b-badge pill variant="light-secondary">
                  VS
                </b-badge>
              </div>
              <hr
                  class="v-divider v-divider--vertical mx-auto"
                  aria-orientation="vertical"
                  role="separator"
                  style="margin-top: 0.5rem; margin-bottom: 0.5rem;"
              >
            </div>
          </b-col>

          <b-col
              md="5"
              class="text-right"
          >
            <span>{{ capitalize($t('made')) }} &nbsp;</span>
            <span
                class="rounded"
                style="padding: 0.3rem; background-color: rgba(40,199,111,0.1);"
            >
              <icon
                  icon="check-square"
                  library="far"
                  class="text-success "
              />
            </span>

            <h5 class="mt-1">
              {{ percent(datas.percentInvoiced) }}
            </h5>
            <div>
              {{ currency(datas.preTaxAmountInvoiced, true) }}
            </div>
          </b-col>

        </b-row>

        <b-row v-if="isReady" class="mt-2">
          <b-col md="12">
            <b-progress max="100">
              <b-progress-bar
                  variant="info"
                  :value="datas.percentOrderedNotInvoiced"
              />
              <b-progress-bar
                  variant="success"
                  :value="datas.percentInvoiced"
              />
            </b-progress>
          </b-col>
        </b-row>

      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch }          from '@vue/composition-api'
import { isEmptyObject }                 from '../../../../../utils/utils'
import { capitalize, currency, percent } from '../../../../../utils/filter'

import IndicatorEvolution from '../../../../../components/indicatorEvolution/IndicatorEvolution.vue'

export default {
  components: { IndicatorEvolution },
  props: {
    datas: {
      type: Object,
      default: () => {}
    },
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !isEmptyObject(props.datas)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      isReady,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { percent, currency, capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>
.v-divider {
  display: block;
  flex: 1 1 100%;
  height: 0px;
  max-height: 0px;
  opacity: 0.16;
  transition: inherit;
  border-style: solid;
  border-width: thin 0 0 0;
}

.v-divider--vertical {
  align-self: stretch;
  border-width: 0 thin 0 0;
  display: inline-flex;
  height: inherit;
  margin-left: -1px;
  max-height: 100%;
  max-width: 0px;
  vertical-align: text-bottom;
  width: 0px;
}

</style>