import { render, staticRenderFns } from "./MainIndicator.vue?vue&type=template&id=4dc9dc27&scoped=true&"
import script from "./MainIndicator.vue?vue&type=script&lang=js&"
export * from "./MainIndicator.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dc9dc27",
  null
  
)

export default component.exports