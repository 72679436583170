<template>
  <b-row>
    <b-col md="4">
      <statistic-card
          v-if="isReady"
          :statistic-title="capitalize($t('average basket 1st purchase'))"
          color="#28c76f"
          :statistic="averageBasket1StPurchase.statistic"
          :evolution="averageBasket1StPurchase.evolution"
          :chart-data="averageBasket1StPurchase.chart"
      />
    </b-col>
    <b-col md="4">
      <statistic-card
          v-if="isReady"
          :statistic-title="capitalize($t('average post-hire basket'))"
          color="#28c76f"
          :statistic="averagePostHireBasket.statistic"
          :evolution="averagePostHireBasket.evolution"
          :chart-data="averageBasket1StPurchase.chart"
      />
    </b-col>
    <b-col md="4">
      <statistic-card
          v-if="isReady"
          :statistic-title="capitalize($t('average recurring basket'))"
          color="#28c76f"
          :statistic="averageRecurringBasket.statistic"
          :evolution="averageRecurringBasket.evolution"
          :chart-data="averageRecurringBasket.chart"
      />
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import StatisticCard            from '../../components/StatisticCard.vue'
import { capitalize }           from '../../../../../../utils/filter'

export default {
  components: { StatisticCard },
  props: {
    averagesBaskets: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !(Object.keys(props.averagesBaskets).length === 0 && props.averagesBaskets.constructor === Object)
    })

    const averageBasket1StPurchase = computed(() => {
      if (isReady.value) {
        return {
          statistic: props.averagesBaskets['Average basket 1st purchase'].statistic,
          evolution: props.averagesBaskets['Average basket 1st purchase'].evolution,
          chart: [{
            data: props.averagesBaskets['Average basket 1st purchase'].chart
          }]
        }
      } else {
        return {
          statistic: 0,
          evolution: 0,
          chart: [{
            data: []
          }],
        }
      }
    })

    const averagePostHireBasket = computed(() => {
      if (isReady.value) {

        return {
          statistic: props.averagesBaskets['Average post-hire basket'].statistic,
          evolution: props.averagesBaskets['Average post-hire basket'].evolution,
          chart: [{
            data: props.averagesBaskets['Average post-hire basket'].chart
          }]
        }
      } else {
        return {
          statistic: 0,
          evolution: 0,
          chart: [{
            data: []
          }],
        }
      }
    })

    const averageRecurringBasket = computed(() => {
      if (isReady.value) {
        return {
          statistic: props.averagesBaskets['Average recurring basket'].statistic,
          evolution: props.averagesBaskets['Average recurring basket'].evolution,
          chart: [{
            data: props.averagesBaskets['Average recurring basket'].chart
          }]
        }
      } else {
        return {
          statistic: 0,
          evolution: 0,
          chart: [{
            data: []
          }],
        }
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      isReady,
      averageBasket1StPurchase,
      averagePostHireBasket,
      averageRecurringBasket,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>