<template>
  <div>
    <b-card :title="capitalize($t('purchasesTypes'))">

      <div class="d-flex justify-content-center mb-2">
        <div class="my-auto mr-50 w-full text-truncate">{{ capitalize($t('numberOfOrders')) }}</div>
        <switch-input
            :model.sync="isTypeOfPurchasesRateAmountTypeLocal"
            class="my-auto"
        />
        <div class="my-auto ml-50 w-full text-truncate">{{ capitalize($t('salesVolume')) }}</div>
      </div>

      <vue-apex-charts
          :options="chartOptions"
          :series="series"
      />
    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch }          from '@vue/composition-api'
import { capitalize, currency, percent } from '../../../../../../utils/filter'

import VueApexCharts from 'vue-apexcharts'
import i18n          from '../../../../../../libs/i18n'

import IndicatorEvolution from '../../../../../../components/indicatorEvolution/IndicatorEvolution.vue'
import SwitchInput        from '../../../../../../components/input/Switch.vue'

export default {
  components: { SwitchInput, VueApexCharts, IndicatorEvolution },
  props: {
    salesTypeOfPurchaseRateChart: {
      type: Object,
      default: () => {}
    },
    isTypeOfPurchasesRateAmountType: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isTypeOfPurchasesRateAmountTypeLocal = computed({
      get () {
        return props.isTypeOfPurchasesRateAmountType
      },
      set (val) {
        emit('update:isTypeOfPurchasesRateAmountType', val)
      }
    })

    const isReady = computed(() => {
      return !(Object.keys(props.salesTypeOfPurchaseRateChart.chart).length === 0 && props.salesTypeOfPurchaseRateChart.chart.constructor === Object)
    })

    const labels = computed(() => {
      return ["Achat mal renseigne", "Premier achat", "Achat récurrent", "Achat post location"]
      // let output = []
      // console.log(props.salesTypeOfPurchaseRateChart.chart)
      // for (const key in props.salesTypeOfPurchaseRateChart.chart) {
      //   output.push(key)
      // }
      // return output
    })

    const series = computed(() => {
      let output = []

      if (isReady.value) {
        labels.value.forEach(l => {
          output.push(props.salesTypeOfPurchaseRateChart.chart[l].value)
        })
      }
      // for (const key in props.salesTypeOfPurchaseRateChart.chart) {
      //   output.push(props.salesTypeOfPurchaseRateChart.chart[key].value)
      // }
      return output
    })

    const chartOptions = computed(() => {
      return {
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        labels: isReady.value ? labels.value : [],
        stroke: { width: 0 },
        colors: ['#ff4560', '#008ffb', '#feb019', '#00e396'],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter (val) {
                    if (isTypeOfPurchasesRateAmountTypeLocal.value) {
                      return currency(val, true)
                    } else {
                      return percent(val, 0)
                    }
                    // return currency(val, true)
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  formatter (w) {
                    const total = w.globals.seriesTotals.reduce((a, b) => { return a + b }, 0)

                    if (isTypeOfPurchasesRateAmountTypeLocal.value) {
                      return currency(total, true)
                    } else {
                      return percent(total, 0)
                    }
                  },
                },
              },
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              if (isTypeOfPurchasesRateAmountTypeLocal.value) {
                return currency(val, true)
              } else {
                return percent(val, 0)
              }
            }
          }
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      isTypeOfPurchasesRateAmountTypeLocal,
      isReady,
      series,
      chartOptions,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { percent, capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>