<template>
  <div>
    <b-card>
      <b-overlay
          :show="!isReady"
          rounded="sm"
      >
        <b-row v-if="isReady">
          <b-col md="7">
            <h1>{{ capitalize($t('estimatedTurnover')) }}</h1>

            <div class="mt-2 pt-50">
              <h2>{{ currency(datas.total, true) }}</h2>
            </div>

            <indicator-evolution
                :indicator="datas.evolution"
            />

          </b-col>

          <b-col md="5">
            <vue-apex-charts
                height="120"
                :options="chartOptions"
                :series="series"
            />
          </b-col>

        </b-row>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { isEmptyObject }        from '../../../../../utils/utils'
import { capitalize, currency } from '../../../../../utils/filter'

import VueApexCharts from 'vue-apexcharts'
import i18n          from '../../../../../libs/i18n'

import IndicatorEvolution from '../../../../../components/indicatorEvolution/IndicatorEvolution.vue'

export default {
  components: { IndicatorEvolution, VueApexCharts },
  props: {
    datas: {
      type: Object,
      default: () => {}
    },
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !isEmptyObject(props.datas)
    })

    const labels = computed(() => {
      let output = []
      for (const key in props.datas.chart) {
        output.push(capitalize(i18n.t(key)))
      }
      return output
    })

    const series = computed(() => {
      let output = []
      for (const key in props.datas.chart) {
        output.push(props.datas.chart[key].value)
      }
      return output
    })

    const chartOptions = computed(() => {
      return {
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        labels: isReady.value?labels.value:[],
        stroke: { width: 0 },
        colors: ["#28C76F", "rgba(40,199,111,0.7)", "rgba(40,199,111,0.5)", "rgba(40,199,111,0.16)"],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter (val) {
                    return currency(val, true)
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  formatter (w) {
                    const total = w.globals.seriesTotals.reduce((a, b) => { return a + b; }, 0)

                    return currency(total, true)
                  },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      currency,

      // Data

      // Computed
      isReady,
      series,
      chartOptions,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>