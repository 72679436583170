<template>
  <div class="d-flex overflow-hidden mb-4">

    <div
        v-for="(button, buttonIndex) in buttons"
        :key="'dashedButton_'+buttonIndex"
    >
      <dashed-button
          :is-selected="selectedLocal == button.value"
          :button="button"

          @click="selectedLocal = button.value"
      />
    </div>

    <div
        v-if="allowAdd"
        class="border d-flex rounded rounded-lg text-center cursor-pointer p-1"
        :class="isAddHovered?'border-primary':''"
        style="border-style: dashed!important;"

        @mouseover="isAddHovered = true"
        @mouseleave="isAddHovered = false"
    >
      <div class="w-100 h-100 d-flex">
        <div class="m-auto">
          <b-avatar
              size="34"
              variant="light-secondary"
          >
            <icon
                icon="plus"
                size="lg"
            />
          </b-avatar>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../utils/filter'

import DashedButton from './_DashedButton.vue'

export default {
  components: {
    DashedButton
  },
  props: {
    buttons: {
      type: Array,
      default: () => []
    },
    selected: {
      type: String,
      default: ''
    },
    allowAdd: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isAddHovered = ref(false)
    // const arrayButtons = ref(
    //     [
    //       {
    //         icon: '',
    //         value: ''
    //       }
    //     ]
    // )

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const selectedLocal = computed({
      get () {
        return props.selected
      },
      set (val) {
        emit('update:selected', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isAddHovered,

      // Computed
      selectedLocal,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>