<template>
  <div>
    <b-card :title="capitalize($t('opportunityCompletionRate'))">
      <!--    <h1>{{ capitalize($t('opportunityCompletionRate')) }}</h1>-->

      <div class="text-center">
        <h2>{{ opportunitiesCompletionRateChart.total }} {{ $t('workFlos') }}</h2>
      </div>

      <vue-apex-charts
          :options="chartOptions"
          :series="series"
      />
    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch }          from '@vue/composition-api'
import { capitalize, currency, percent } from '../../../../../../utils/filter'

import VueApexCharts from 'vue-apexcharts'
import i18n          from '../../../../../../libs/i18n'

import IndicatorEvolution from '../../../../../../components/indicatorEvolution/IndicatorEvolution.vue'

export default {
  components: { VueApexCharts, IndicatorEvolution },
  props: {
    opportunitiesCompletionRateChart: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !(Object.keys(props.opportunitiesCompletionRateChart).length === 0 && props.opportunitiesCompletionRateChart.constructor === Object)
    })

    const labels = computed(() => {
      let output = []
      for (const key in props.opportunitiesCompletionRateChart.chart) {
        output.push(capitalize(i18n.t(key)))
      }
      return output
    })

    const series = computed(() => {
      let output = []
      for (const key in props.opportunitiesCompletionRateChart.chart) {
        output.push(props.opportunitiesCompletionRateChart.chart[key].value)
      }
      return output
    })

    const chartOptions = computed(() => {
      return {
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        labels: isReady.value ? labels.value : [],
        stroke: { width: 0 },
        // colors: ["#28C76F", "rgba(40,199,111,0.7)", "rgba(40,199,111,0.5)", "rgba(40,199,111,0.16)"],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter (val) {
                    return displayRate(val)
                    // return percent(val, 0)
                    // return currency(val, true)
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  formatter (w) {
                    const total = w.globals.seriesTotals.reduce((a, b) => { return a + b }, 0)

                    return displayRate(total)
                    // return percent(total, 0)
                  },
                },
              },
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return displayRate(val)
              // return percent(val, 0)
            }
          }
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const displayRate = (qty) => {
      let output = "0 (0%)"
      if (props.opportunitiesCompletionRateChart.total != 0) {
        let x = (qty*100)/props.opportunitiesCompletionRateChart.total
        output = qty + " ("+percent(x) + ")"
      }

      return output
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      isReady,
      series,
      chartOptions,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { percent, capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>