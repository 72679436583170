<template>
  <div>
    <vue-apex-charts
        type="bar"
        height="400"
        :options="chartOptions"
        :series="series"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../../../../utils/filter'

import VueApexCharts from 'vue-apexcharts'

import i18n   from '../../../../../../libs/i18n'
import moment from 'moment'

export default {
  components: {
    VueApexCharts
  },
  props: {
    salesOrdersChart: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !(Object.keys(props.salesOrdersChart).length === 0 && props.salesOrdersChart.constructor === Object)
    })

    const orderedInvoicedByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.salesOrdersChart.chart).forEach(monthValArray => {
          output.push(monthValArray.orderedInvoiced)
        })
      }

      return output
    })

    const orderedUnInvoicedByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.salesOrdersChart.chart).forEach(monthValArray => {
          output.push(monthValArray.orderedUnInvoiced)
        })
      }

      return output
    })

    const offerWithoutOpportunityDetailsByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.salesOrdersChart.chart).forEach(monthValArray => {
          output.push(monthValArray.offerWithoutOpportunity)
        })
      }

      return output
    })

    const months = computed(() => {
      if (isReady.value) {
        return Object.keys(props.salesOrdersChart.chart)
      } else {
        return []
      }
    })

    const series = computed(() => {
      return [
        {
          name: capitalize(i18n.t('orderedInvoiced')),
          data: orderedInvoicedByMonths.value,
        },
        {
          name: capitalize(i18n.t('orderedUnInvoiced')),
          data: orderedUnInvoicedByMonths.value,
        },
        {
          name: capitalize(i18n.t('offerWithoutOpportunity')),
          data: offerWithoutOpportunityDetailsByMonths.value,
        },
      ]
    })

    const chartOptions = computed(() => {
      return {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        colors: ['#826af9', '#d2b0ff', '#f8d3ff'],
        plotOptions: {
          bar: {
            columnWidth: '20',
            borderRadius: 5,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: 'top',
          fontSize: '14px',
          fontFamily: 'Montserrat',
          horizontalAlign: 'left',
        },
        stroke: {
          show: true,
          colors: ['transparent'],
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: months.value,
          labels: {
            formatter: (val) => {
              return capitalize(moment(val).format('MMMM YYYY'))
            }
          }
        },
        yaxis: {
          // opposite: isRtl,
          labels: {
            formatter: (val) => {
              return currency(val, true)
            }
          }
        },
        fill: {
          opacity: 1,
        },
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      series,
      chartOptions,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>