<template>
  <div
      class="border d-flex rounded rounded-lg text-center mr-2 cursor-pointer p-1"
      :class="(isSelected || isHovered)?'border-primary':''"
      :style="!isSelected?'border-style: dashed!important;':''"

      @click="$emit('click')"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
  >
    <div class="m-auto">
      <b-avatar
          size="34"
          :variant="isSelected?'light-primary':'light-secondary'"
      >
        <icon
            :icon="button.icon"
            size="lg"
        />
      </b-avatar>
      <h4 class="mt-50">{{ capitalize($t(button.value)) }}</h4>
    </div>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../utils/filter'

export default {
  components: {},
  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    button: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isHovered = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isHovered,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>