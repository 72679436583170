<template>
  <div>
    <b-row>

      <b-col md="8">
        <b-card :title="capitalize($t('opportunitiesIndicators'))">
          <dashed-buttons
              :buttons="dashedButtons"
              :selected.sync="selectedDashedButton"
              :allow-add="false"
          />

          <pipe-status-main-chart
              v-if="selectedDashedButton == 'pipe status'"
              :opportunities-pipe-status-chart="opportunitiesPipeStatusChart"
          />

          <marketing-main-chart
              v-if="selectedDashedButton == 'marketing'"
              :opportunities-marketing-chart="opportunitiesMarketing.chart"
          />

          <quality-main-chart
              v-if="selectedDashedButton == 'leads qualities'"
              :opportunities-quality="opportunitiesQualityChart"
          />

          <time-spent-main-chart
              v-if="selectedDashedButton == 'time spent'"
              :opportunities-time-spent-chart="opportunitiesTimeSpent"
          />

        </b-card>

        <b-row
            v-if="selectedDashedButton == 'time spent'"
        >
          <b-col md="12">
            <time-spent-ratios
                :opportunities-time-spent-ratios="opportunitiesTimeSpentRatio"
            />
          </b-col>
        </b-row>

      </b-col>


      <b-col md="4">
        <completion-rate-chart
            v-if="selectedDashedButton == 'pipe status'"
            :opportunities-completion-rate-chart="opportunitiesCompletionRateChart"
        />

        <pipe-status-pie-chart
            v-if="selectedDashedButton == 'pipe status'"
            :opportunities-pipe-status-pie-chart="opportunitiesPipeStatusChart"
        />

        <marketing-rate-chart
            v-if="selectedDashedButton == 'marketing'"
            :opportunities-marketing-rate-chart="opportunitiesMarketingRateChart"
        />

        <ratios-by-sources
            v-if="selectedDashedButton == 'leads qualities'"
            :ratios-by-sources-rate-chart="opportunitiesRatiosBySourcesChart"
        />

        <time-spent-rate
            v-if="selectedDashedButton == 'time spent'"
            :opportunities-time-spent="opportunitiesTimeSpentRate"
        />

      </b-col>

    </b-row>

    <b-row>
      <b-col md="12">
        <opportunities-list
            :list-entity-type="listEntityType"
            :opportunities-list="opportunitiesList"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../../../utils/filter'

import store from '../../../../../store'

import DashedButtons       from '../../../../../components/dashedButtons/DashedButtons.vue'
import PipeStatusMainChart from './pipeStatus/PipeStatusMainChart.vue'
import CompletionRateChart from './pipeStatus/CompletionRateChart.vue'
import MarketingMainChart  from './marketing/MarketingMainChart.vue'
import MarketingRateChart  from './marketing/MarketingRateChart.vue'
import QualityMainChart    from './quality/QualityMainChart.vue'
import RatiosBySources     from './quality/RatiosBySources.vue'
import OpportunitiesList   from './OpportunitiesList.vue'
import TimeSpentMainChart  from './timeSpent/TimeSpentMainChart.vue'
import TimeSpentRate       from './timeSpent/TimeSpentRate.vue'
import VerticalIndicator   from '../components/VerticalIndicator.vue'
import TimeSpentRatios     from './timeSpent/TimeSpentRatios.vue'
import PipeStatusPieChart  from './pipeStatus/PipeStatusPieChart.vue'

export default {
  components: {
    PipeStatusPieChart,
    TimeSpentRatios,
    VerticalIndicator,
    TimeSpentRate,
    OpportunitiesList,
    DashedButtons,
    PipeStatusMainChart,
    CompletionRateChart,
    MarketingMainChart,
    MarketingRateChart,
    QualityMainChart,
    RatiosBySources,
    TimeSpentMainChart
  },
  props: {
    localFilters: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const dashedButtons = ref([
      {
        icon: 'chart-pie',
        value: 'pipe status'
      },
      {
        icon: 'chart-bar',
        value: 'transformation'
      },
      {
        icon: 'mail-bulk',
        value: 'marketing'
      },
      {
        icon: 'chart-bar',
        value: 'leads qualities'
      },
      {
        icon: 'user-clock',
        value: 'time spent'
      },
    ])
    const selectedDashedButton = ref('pipe status')
    const opportunitiesPipeStatusChart = ref({
      chart: {
        bar: {},
        pie: {}
      },
      list: [],
    })
    const opportunitiesCompletionRateChart = ref({})
    const opportunitiesMarketing = ref({
      chart: {},
      list: [],
    })
    const opportunitiesMarketingRateChart = ref({})
    const opportunitiesQualityChart = ref({
      global: {},
      chart: {},
      list: [],
    })
    const opportunitiesRatiosBySourcesChart = ref({})
    const opportunitiesTimeSpent = ref({
      global: {},
      chart: {},
      list: [],
    })
    const opportunitiesTimeSpentRate = ref({
      chart: {},
      total: 0,
    })
    const opportunitiesTimeSpentRatio = ref({})
    const listEntityType = ref('workFlo')

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const opportunitiesList = computed(() => {
      if (selectedDashedButton.value == 'pipe status') {
        listEntityType.value = 'workFlo'
        return opportunitiesPipeStatusChart.value.list
      } else if (selectedDashedButton.value == 'marketing') {
        listEntityType.value = 'workFlo'
        return opportunitiesMarketing.value.list
      } else if (selectedDashedButton.value == 'leads qualities') {
        listEntityType.value = 'workFlo'
        return opportunitiesQualityChart.value.list
      } else if (selectedDashedButton.value == 'time spent') {
        listEntityType.value = 'customer'
        return opportunitiesTimeSpent.value.list
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(selectedDashedButton, val => {
      performRequests(props.localFilters)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetRequests = () => {
      opportunitiesPipeStatusChart.value = {
        chart: {
          bar: {},
          pie: {}
        },
        list: [],
      }
      opportunitiesCompletionRateChart.value = {}
      opportunitiesMarketing.value = {
        chart: {}
      }
      opportunitiesMarketingRateChart.value = {}
      opportunitiesQualityChart.value = {
        global: {},
        chart: {},
        list: [],
      }
      opportunitiesRatiosBySourcesChart.value = {}
      opportunitiesTimeSpent.value = {
        global: {},
        chart: {},
        list: [],
      }
      opportunitiesTimeSpentRate.value = {
        chart: {},
        total: 0,
      }
      opportunitiesTimeSpentRatio.value = {}
    }

    const performRequests = (updatedFilters) => {
      resetRequests()

      if (selectedDashedButton.value == 'pipe status') {
        opportunitiesPipeStatusChartRequest(updatedFilters)
        opportunitiesCompletionRateChartRequest(updatedFilters)
      } else if (selectedDashedButton.value == 'marketing') {
        opportunitiesMarketingRequest(updatedFilters)
        opportunitiesMarketingRateChartRequest(updatedFilters)
      } else if (selectedDashedButton.value == 'transformation') {
        // salesPipeForecastChartRequest(updatedFilters)
      } else if (selectedDashedButton.value == 'leads qualities') {
        opportunitiesQualityChartRequest(updatedFilters)
        opportunitiesRatiosBySourcesChartRequest(updatedFilters)
      } else if (selectedDashedButton.value == 'time spent') {
        opportunitiesTimeSpentChartRequest(updatedFilters)
        opportunitiesTimeSpentRateRequest(updatedFilters)
        opportunitiesTimeSpentRatioRequest(updatedFilters)
      }
    }

    const opportunitiesPipeStatusChartRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'opportunities_pipeStatus',
        filters: updatedFilters
      })
          .then(response => {
            opportunitiesPipeStatusChart.value = response.data.results
          })
    }

    const opportunitiesCompletionRateChartRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'opportunities_completionRate',
        filters: updatedFilters
      })
          .then(response => {
            opportunitiesCompletionRateChart.value = response.data.results
          })
    }

    const opportunitiesMarketingRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'opportunities_marketing',
        filters: updatedFilters
      })
          .then(response => {
            opportunitiesMarketing.value = response.data.results
          })
    }

    const opportunitiesMarketingRateChartRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'opportunities_marketingRate',
        filters: updatedFilters
      })
          .then(response => {
            opportunitiesMarketingRateChart.value = response.data.results
          })
    }

    const opportunitiesQualityChartRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'opportunities_quality',
        filters: updatedFilters
      })
          .then(response => {
            opportunitiesQualityChart.value = response.data.results
          })
    }

    const opportunitiesRatiosBySourcesChartRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'opportunities_ratiosBySources',
        filters: updatedFilters
      })
          .then(response => {
            opportunitiesRatiosBySourcesChart.value = response.data.results
          })
    }

    const opportunitiesTimeSpentChartRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'opportunities_timeSpent',
        filters: updatedFilters
      })
          .then(response => {
            opportunitiesTimeSpent.value = response.data.results
          })
    }

    const opportunitiesTimeSpentRateRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'opportunities_timeSpentRate',
        filters: updatedFilters
      })
          .then(response => {
            opportunitiesTimeSpentRate.value = response.data.results
          })
    }

    const opportunitiesTimeSpentRatioRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'opportunities_timeSpentRatio',
        filters: updatedFilters
      })
          .then(response => {
            opportunitiesTimeSpentRatio.value = response.data.results
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    performRequests(props.localFilters)

    return {
      // Components

      // Data
      dashedButtons,
      selectedDashedButton,
      opportunitiesPipeStatusChart,
      opportunitiesCompletionRateChart,
      opportunitiesMarketing,
      opportunitiesMarketingRateChart,
      opportunitiesQualityChart,
      opportunitiesRatiosBySourcesChart,
      opportunitiesTimeSpent,
      opportunitiesTimeSpentRate,
      opportunitiesTimeSpentRatio,
      listEntityType,

      // Computed
      opportunitiesList,

      // Methods
      performRequests,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>