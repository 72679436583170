<template>
  <div>
    <b-card>
      <list-indicator
          :rows="rows"
          :list-entity-type="listEntityType"
      >
        <template #listView_cell_preTaxAmount="{item}">
          {{currency(item.row.preTaxAmount)}}
        </template>

        <template #listView_cell_totalAmount="{item}">
          {{currency(item.row.totalAmount)}}
        </template>

        <template #listView_cell_balance="{item}">
          {{currency(item.row.balance)}}
        </template>

      </list-indicator>
    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { currency, capitalize }  from '../../../../../utils/filter'

import i18n   from '../../../../../libs/i18n'
import moment from 'moment'

import ListIndicator from '../components/ListIndicator.vue'

export default {
  components: {
    ListIndicator
  },
  props: {
    salesList: {
      type: Array,
      default: () => []
    },
    listEntityType: {
      type: String,
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------


    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const rows = computed(() => {
      let output = []

      if (props.listEntityType == 'offer') {
        props.salesList.forEach(o => {
          output.push({
            id: o.id,
            number: o.billNumber,
            billingDate: moment(o.billingDate).format('YYYY-MM-DD'),
            customer: o.customerCompany!=null?o.customerCompany._display:o.customerIndividual._display,
            title: o.title,
            preTaxAmount: o.preTaxAmount,
            totalAmount: o.totalAmount,
            balance: o._billable_preTaxAmount_balance,
          })
        })
      } else if (props.listEntityType == 'workFlo') {
        props.salesList.forEach(w => {
          output.push({
            id: w.id,
            company: w.company._display,
            status: w.status.name,
            title: w.title,
            typeOfPurchase: w.typeOfPurchase
          })
        })
      }

      return output
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      rows,
      // searchTerm,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { currency, capitalize },
  mounted () {
  },
  created () {
  }
}
</script>


<style lang="scss">
@import '~@/assets/scss/vue/libs/vue-good-table';
</style>