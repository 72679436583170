<template>
  <div>
    <vue-apex-charts
        type="bar"
        height="400"
        :options="chartOptions"
        :series="series"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../../../../utils/filter'

import VueApexCharts from 'vue-apexcharts'

import i18n   from '../../../../../../libs/i18n'
import moment from 'moment'
import { far } from '@fortawesome/free-regular-svg-icons'

export default {
  components: {
    VueApexCharts
  },
  props: {
    opportunitiesMarketingChart: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !(Object.keys(props.opportunitiesMarketingChart).length === 0 && props.opportunitiesMarketingChart.constructor === Object)
    })

    const internetResearchByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.opportunitiesMarketingChart).forEach(monthValArray => {
          output.push(monthValArray["Internet research"])
        })
      }

      return output
    })

    const internetResearchSEOByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.opportunitiesMarketingChart).forEach(monthValArray => {
          output.push(monthValArray["Internet research SEO"])
        })
      }

      return output
    })

    const internetResearchGADSByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.opportunitiesMarketingChart).forEach(monthValArray => {
          output.push(monthValArray["Internet research GADS"])
        })
      }

      return output
    })

    const internetResearchMADSByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.opportunitiesMarketingChart).forEach(monthValArray => {
          output.push(monthValArray["Internet research MADS"])
        })
      }

      return output
    })

    const socialNetworkingByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.opportunitiesMarketingChart).forEach(monthValArray => {
          output.push(monthValArray["Social networking"])
        })
      }

      return output
    })

    const pressMediaByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.opportunitiesMarketingChart).forEach(monthValArray => {
          output.push(monthValArray["Press"])
        })
      }

      return output
    })

    const networkByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.opportunitiesMarketingChart).forEach(monthValArray => {
          output.push(monthValArray["Network"])
        })
      }

      return output
    })

    const newsletterByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.opportunitiesMarketingChart).forEach(monthValArray => {
          output.push(monthValArray["Newsletter"])
        })
      }

      return output
    })

    const eventByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.opportunitiesMarketingChart).forEach(monthValArray => {
          output.push(monthValArray["Event"])
        })
      }

      return output
    })

    const farmingByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.opportunitiesMarketingChart).forEach(monthValArray => {
          output.push(monthValArray["Farming"])
        })
      }

      return output
    })

    const noSourceByMonths = computed(() => {
      let output = []

      if (isReady.value) {
        Object.values(props.opportunitiesMarketingChart).forEach(monthValArray => {
          output.push(monthValArray["No source"])
        })
      }

      return output
    })

    const months = computed(() => {
      if (isReady.value) {
        return Object.keys(props.opportunitiesMarketingChart)
      } else {
        return []
      }
    })

    const series = computed(() => {
      return [
        {
          name: capitalize(i18n.t('internetResearch')),
          data: internetResearchByMonths.value,
        },
        {
          name: capitalize(i18n.t('internetResearchSEO')),
          data: internetResearchSEOByMonths.value,
        },
        {
          name: capitalize(i18n.t('internetResearchGADS')),
          data: internetResearchGADSByMonths.value,
        },
        {
          name: capitalize(i18n.t('internetResearchMADS')),
          data: internetResearchMADSByMonths.value,
        },
        {
          name: capitalize(i18n.t('socialNetworking')),
          data: socialNetworkingByMonths.value,
        },
        {
          name: capitalize(i18n.t('press/media')),
          data: pressMediaByMonths.value,
        },
        {
          name: capitalize(i18n.tc('network',2)),
          data: networkByMonths.value,
        },
        {
          name: capitalize(i18n.t('newsletter')),
          data: newsletterByMonths.value,
        },
        {
          name: capitalize(i18n.tc('event')),
          data: eventByMonths.value,
        },
        {
          name: capitalize(i18n.tc('farming')),
          data: farmingByMonths.value,
        },
        {
          name: capitalize(i18n.tc('noSource')),
          data: noSourceByMonths.value,
        },
      ]
    })

    const chartOptions = computed(() => {
      return {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        // colors: ['#826af9', '#d2b0ff', '#f8d3ff'],
        plotOptions: {
          bar: {
            columnWidth: '20',
            borderRadius: 5,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: 'top',
          fontSize: '14px',
          fontFamily: 'Montserrat',
          horizontalAlign: 'left',
        },
        stroke: {
          show: true,
          colors: ['transparent'],
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: months.value,
          labels: {
            formatter: (val) => {
              return capitalize(moment(val).format('MMMM YYYY'))
            }
          }
        },
        yaxis: {
          // opposite: isRtl,
          labels: {
            formatter: (val) => {
              return val
            }
          }
        },
        fill: {
          opacity: 1,
        },
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      series,
      chartOptions,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>