<template>
  <b-media
      no-body
      class="mb-1 mb-md-0 p-1 overflow-hidden cursor-pointer rounded"
      :class="{'border-2 border-primary': isSelected, 'bg-primary bg-lighten-5': isHovered}"
      @mouseover="isHovered=true"
      @mouseleave="isHovered=false"
      @click="$emit('click')"
  >
    <b-media-aside
        class="mr-1"
    >
      <b-avatar
          size="48"
          variant="light-primary"
      >
        <icon
            icon="chart-pie"
            size="lg"
        />
      </b-avatar>
    </b-media-aside>
    <b-media-body class="my-auto">
      <div class="d-flex">
        <h4 class="font-weight-bolder mb-0 mr-50">
          {{ currency(sales.orderedTurnover, true) }}
        </h4>
        <b-badge
            pill
            :variant="badgeColor"
            class="my-auto"
        >
          {{ displayNumberWithSymbol(currency(sales.evolution, true)) }}
        </b-badge>
      </div>
      <b-card-text class="font-small-3 mb-0">
        {{ capitalize($t('orderedTurnover')) }}
      </b-card-text>
    </b-media-body>
  </b-media>
</template>

<script>
import { ref, computed, watch }                          from '@vue/composition-api'
import { capitalize, currency, displayNumberWithSymbol } from '../../../../../utils/filter'

export default {
  components: {},
  props: {
    sales: {
      type: Object,
      default: () => {}
    },
    badgeColor: {
      type: String
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isHovered = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isHovered,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { displayNumberWithSymbol, currency, capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>