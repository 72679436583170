<template>
  <b-card no-body>
    <b-card-body class="pb-0">
      <b-avatar
          v-if="icon"
          class="mb-1"
          :variant="`light-${color}`"
          size="45"
      >
        <icon
            :icon="icon"
            size="lg"
        />
      </b-avatar>
      <div class="truncate">
        <h2>{{ statisticTitle }}</h2>
      </div>
    </b-card-body>

    <vue-apex-charts
        type="area"
        height="100"
        width="100%"
        :options="chartOptions"
        :series="chartData"
    />

    <div class="p-1">
      <div class="d-flex justify-content-between">
        <h2>{{ currency(statistic, true) }}</h2>
        <div
            :class="evolution>=0?'text-success':'text-danger'"
        >{{ percent(evolution, 1) }}
        </div>
      </div>
    </div>

  </b-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import VueApexCharts         from 'vue-apexcharts'
import { currency, percent } from '../../../../../utils/filter'

export default {
  components: {
    VueApexCharts
  },
  props: {
    icon: {
      type: String,
    },
    statistic: {
      type: Number,
    },
    evolution: {
      type: Number,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#7367f0',
    },
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const chartOptions = ref({
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2.5,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 0.9,
          opacityFrom: 0.5,
          opacityTo: 0.2,
          stops: [0, 80, 100],
        },
      },
      xaxis: {
        type: 'numeric',
        lines: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        labels: { show: false },
      },
      yaxis: [
        {
          y: 0,
          offsetX: 0,
          offsetY: 0,
          padding: {
            left: 0,
            right: 0,
          },
        },
      ],
      tooltip: {
        enabled: false,
        x: { show: false },
      },
      theme: {
        monochrome: {
          enabled: true,
          color: props.color,
          shadeTo: 'light',
          shadeIntensity: 0.65,
        },
      },
    })

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      chartOptions,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { percent, currency },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>