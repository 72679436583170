<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-card>
          <vertical-indicator
              :is-ready="isReady"
              title="averageTime"
              icon="clock"
              color="info"
              :value="averageTimeTotal.value"
              :evolution="averageTimeTotal.evolution"

          />
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <horizontal-indicator
              :is-ready="isReady"
              title="averageTimeDaily"
              icon="clock"
              color="info"
              :value="averageTimeDaily.value"
          />
        </b-card>

        <b-card>
          <horizontal-indicator
              :is-ready="isReady"
              title="daily time dedicated to workFlos"
              icon="percent"
              color="success"
              :value="averageTimeDailyPercent.value"
          />
        </b-card>
      </b-col>
      <b-col md="4"></b-col>
    </b-row>



  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import VerticalIndicator        from '../../components/VerticalIndicator.vue'
import i18n                     from '../../../../../../libs/i18n'
import { percent }              from '../../../../../../utils/filter'
import HorizontalIndicator      from '../../components/HorizontalIndicator.vue'

export default {
  components: { HorizontalIndicator, VerticalIndicator },
  props: {
    opportunitiesTimeSpentRatios: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !(Object.keys(props.opportunitiesTimeSpentRatios).length === 0 && props.opportunitiesTimeSpentRatios.constructor === Object)
    })

    const averageTimeTotal = computed(() => {
      if (isReady.value) {
        let val = Math.round(props.opportunitiesTimeSpentRatios['average time']['value'], 2)
        let evolution = props.opportunitiesTimeSpentRatios['average time']['evolution']


        console.log({
          value: val + ' ' + i18n.tc('hour', val),
          evolution: evolution
        })
        return {
          value: val + ' ' + i18n.tc('hour', val),
          evolution: evolution
        }
      } else {
        return {
          value: "0",
          evolution: 0
        }
      }
    })

    const averageTimeDaily = computed(() => {
      if (isReady.value) {
        let val = Math.round(props.opportunitiesTimeSpentRatios['average time opened day']['value'], 2)
        // let evolution = props.opportunitiesTimeSpentRatios['average time opened day']['evolution']


        // console.log({
        //   value: val + 'h/j',
        //   // evolution: evolution
        // })
        return {
          value: val + 'h/j',
          // evolution: evolution
        }
      } else {
        return {
          value: "0",
          // evolution: 0
        }
      }
    })

    const averageTimeDailyPercent = computed(() => {
      if (isReady.value) {
        let val = Math.round(props.opportunitiesTimeSpentRatios['average time opened day']['percent'], 2)

        return {
          value: percent(val),
        }
      } else {
        return {
          value: "0",
        }
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      isReady,
      averageTimeTotal,
      averageTimeDaily,
      averageTimeDailyPercent,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>