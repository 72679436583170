<template>
  <div>
    <b-row>
      <b-col md="4">
        <estimated-turnover
            :datas="estimatedTurnover"
        />
      </b-col>
      <b-col md="4">
        <customer-work-flos
            :datas="customerWorkFlos"
        />
      </b-col>
      <b-col md="4">
        <late-work-flos
            :datas="lateWorkFlos"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="4">
        <orders
            :datas="orders"
        />
      </b-col>
      <b-col md="2">
        <completion-rate
            :datas="completionRate"
        />
      </b-col>
      <b-col md="2">
        <time-to-completion
            :datas="timeToCompletion"
        />
      </b-col>
      <b-col md="2">
        <average-basket
            :datas="averageBasket"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import CompletionRate           from './main/CompletionRate.vue'
import AverageBasket            from './main/AverageBasket.vue'
import EstimatedTurnover        from './main/EstimatedTurnover.vue'
import Orders                   from './main/Orders.vue'
import LateWorkFlos             from './main/LateWorkFlos.vue'
import CustomerWorkFlos         from './main/CustomerWorkFlos.vue'
import TimeToCompletion         from './main/TimeToCompletion.vue'
import store                    from '../../../../store'

export default {
  components: {
    TimeToCompletion,
    CustomerWorkFlos,
    LateWorkFlos,
    Orders,
    EstimatedTurnover,
    AverageBasket,
    CompletionRate
  },
  props: {
    localFilters: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const estimatedTurnover = ref({})
    const customerWorkFlos = ref({})
    const lateWorkFlos = ref({})
    const orders = ref({})
    const averageBasket = ref({})
    const timeToCompletion = ref({})
    const completionRate = ref({})

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetRequests = () => {
      estimatedTurnover.value = {}
      customerWorkFlos.value = {}
      lateWorkFlos.value = {}
      orders.value = {}
      averageBasket.value = {}
      timeToCompletion.value = {}
      completionRate.value = {}
    }

    const performRequests = (updatedFilters) => {
      resetRequests()

      estimatedTurnoverRequest(updatedFilters)
      customerWorkFlosRequest(updatedFilters)
      lateWorkFlosRequest(updatedFilters)
      ordersRequest(updatedFilters)
      timeToCompletionRequest(updatedFilters)
      averageBasketRequest(updatedFilters)
      completionRateRequest(updatedFilters)
    }

    const estimatedTurnoverRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'estimatedTurnover',
        filters: updatedFilters
      })
          .then(response => {
            estimatedTurnover.value = response.data.results
          })
    }

    const customerWorkFlosRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'customerWorkFlos',
        filters: updatedFilters
      })
          .then(response => {
            customerWorkFlos.value = response.data.results
          })
    }

    const lateWorkFlosRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'lateWorkFlos',
        filters: updatedFilters
      })
          .then(response => {
            lateWorkFlos.value = response.data.results
          })
    }

    const ordersRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'orders',
        filters: updatedFilters
      })
          .then(response => {
            orders.value = response.data.results
          })
    }
    const averageBasketRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'averageBasket',
        filters: updatedFilters
      })
          .then(response => {
            averageBasket.value = response.data.results
          })
    }

    const timeToCompletionRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'timeToCompletion',
        filters: updatedFilters
      })
          .then(response => {
            timeToCompletion.value = response.data.results
          })
    }

    const completionRateRequest = (updatedFilters) => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'completionRate',
        filters: updatedFilters
      })
          .then(response => {
            completionRate.value = response.data.results
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    performRequests(props.localFilters)

    return {
      // Components

      // Data
      estimatedTurnover,
      customerWorkFlos,
      lateWorkFlos,
      orders,
      averageBasket,
      timeToCompletion,
      completionRate,

      // Computed

      // Methods
      performRequests,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>