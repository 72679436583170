<template>
  <div>
    <b-card :title="capitalize($t('ratiosBySources'))">

      <div class="d-flex justify-content-center">
        <div class="my-auto mr-50 w-full text-truncate">{{ capitalize($t('success/lost')) }}</div>
        <switch-input
            :model.sync="isAverageBasketType"
            class="my-auto"
        />
        <div class="my-auto ml-50 w-full text-truncate">{{ capitalize($t('averageBasket')) }}</div>
      </div>

      <vue-apex-charts
          type="radar"
          height="400"
          :options="chartOptions"
          :series="series"
      />
    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch }          from '@vue/composition-api'
import { capitalize, currency, percent } from '../../../../../../utils/filter'

import VueApexCharts from 'vue-apexcharts'

import SwitchInput from '../../../../../../components/input/Switch.vue'
import i18n        from '../../../../../../libs/i18n'

export default {
  components: { VueApexCharts, SwitchInput },
  props: {
    ratiosBySourcesRateChart: {
      type: Object,
      default: () => {}
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isAverageBasketType = ref(false)
    const labels = ref([
      capitalize(i18n.t('internetResearch')),
      capitalize(i18n.t('socialNetworking')),
      capitalize(i18n.t('press/media')),
      capitalize(i18n.tc('network', 2)),
      capitalize(i18n.t('newsletter')),
      capitalize(i18n.tc('event', 1)),
      capitalize(i18n.t('farming')),
      capitalize(i18n.t('notSpecified')),
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    const isReady = computed(() => {
      return !(Object.keys(props.ratiosBySourcesRateChart).length === 0 && props.ratiosBySourcesRateChart.constructor === Object)
    })

    const series = computed(() => {
      if (isAverageBasketType.value) {
        let datas = []
        if (!isReady.value) {
          datas = [0, 0, 0, 0, 0, 0, 0, 0]
        } else {
          Object.keys(props.ratiosBySourcesRateChart).forEach(source => {
            datas.push(props.ratiosBySourcesRateChart[source]['average basket'])
          })
        }

        return [{
          name: capitalize(i18n.t('averageBasket')),
          data: datas
        }]
      } else {
        let wonDatas = []
        let lostDatas = []

        if (!isReady.value) {
          wonDatas = [0, 0, 0, 0, 0, 0, 0, 0]
          lostDatas = [0, 0, 0, 0, 0, 0, 0, 0]
        } else {
          Object.keys(props.ratiosBySourcesRateChart).forEach(source => {
            wonDatas.push(props.ratiosBySourcesRateChart[source]['won quantities'])
            lostDatas.push(props.ratiosBySourcesRateChart[source]['lost quantities'])
          })
        }

        return [{
          name: capitalize(i18n.t('success')),
          data: wonDatas
        }, {
          name: capitalize(i18n.t('lost')),
          data: lostDatas
        }]
      }
    })

    const chartOptions = computed(() => {
      return {
        chart: {
          type: 'radar',
          dropShadow: {
            enabled: true,
            blur: 8,
            left: 1,
            top: 1,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          background: {
            foreColor: ['#ebe9f1'],
          },
          formatter: function (val, opts) {
            if (isAverageBasketType.value) {
              return currency(val, true)
            } else {
              return val
            }
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            // gradientToColors: [$themeColors.primary, $themeColors.info],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        xaxis: {
          categories: labels.value,

        },
        yaxis: {
          show: false,
        },
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isAverageBasketType,

      // Computed
      series,
      chartOptions,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>