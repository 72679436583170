import { render, staticRenderFns } from "./AveragesBaskets.vue?vue&type=template&id=e43b25d4&scoped=true&"
import script from "./AveragesBaskets.vue?vue&type=script&lang=js&"
export * from "./AveragesBaskets.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e43b25d4",
  null
  
)

export default component.exports