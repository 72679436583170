<template>
  <div>
    <b-card>
      <template #header>
        <h4 class="mb-0">{{ capitalize($t('mainIndicators')) }}</h4>
        <div class="text-secondary">
          <b-badge
              pill
              variant="light-secondary"
              class="my-auto"
          >
            {{ capitalize($t('sinceLastWeek')) }}
          </b-badge>
        </div>
      </template>

      <b-overlay
          :show="!isReady"
          rounded="sm"
      >
        <b-row v-if="isReady">
          <!--        Invoiced turnover-->
          <b-col md="2">
            <b-media
                no-body
                class="mb-1 mb-md-0"
            >
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-primary"
                >
                  <icon
                      icon="chart-pie"
                      size="lg"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <div class="d-flex">
                  <h4 class="font-weight-bolder mb-0 mr-50">
                    {{ currency(datas.invoicedTurnover.invoicedTurnover, true) }}
                  </h4>
                  <b-badge
                      pill
                      :variant="getBadgeColor(datas.invoicedTurnover.evolution)"
                      class="my-auto"
                  >
                    {{ displayNumberWithSymbol(currency(datas.invoicedTurnover.evolution, true)) }}
                  </b-badge>
                </div>
                <b-card-text class="font-small-3 mb-0">
                  {{ capitalize($t('invoicedTurnover')) }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

          <!--        Ordered turnover (sales)-->
          <b-col md="2">
            <sales-main-indicator-button
                :sales="datas.orderedTurnover"
                :badge-color="getBadgeColor(datas.orderedTurnover.evolution)"
                :is-selected="selectedIndicatorView == 'sales'"

                @click="$emit('update:selectedIndicatorView', 'sales')"
            />
          </b-col>

          <!--        WorkFlos -->
          <b-col md="2">
            <opportunities-main-indicator-button
                :opportunities="datas.countWorkFlos"
                :badge-color="getBadgeColor(datas.countWorkFlos.evolution)"
                :is-selected="selectedIndicatorView == 'opportunities'"

                @click="$emit('update:selectedIndicatorView', 'opportunities')"
            />
          </b-col>

          <!--        Count interventions -->
          <b-col md="2">
            <b-media
                no-body
                class="mb-1 mb-md-0"
            >
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-secondary"
                >
                  <icon
                      icon="briefcase"
                      size="lg"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <div class="d-flex">
                  <h4 class="font-weight-bolder mb-0 mr-50">
                    {{ datas.countInterventions.count }}
                  </h4>
                  <b-badge
                      pill
                      :variant="getBadgeColor(datas.countInterventions.evolution)"
                      class="my-auto"
                  >
                    {{ displayNumberWithSymbol(datas.countInterventions.evolution) }}
                  </b-badge>
                </div>
                <b-card-text class="font-small-3 mb-0">
                  {{ capitalize($tc('interventionDays', datas.countInterventions.count)) }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

          <!--        Expenses -->
          <b-col md="2">
            <b-media
                no-body
                class="mb-1 mb-md-0"
            >
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-danger"
                >
                  <icon
                      icon="euro-sign"
                      size="lg"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <div class="d-flex">
                  <h4 class="font-weight-bolder mb-0 mr-50">
                    {{ currency(datas.expenses.expenses, true) }}
                  </h4>
                  <b-badge
                      pill
                      :variant="getBadgeColor(datas.expenses.evolution)"
                      class="my-auto"
                  >
                    {{ displayNumberWithSymbol(currency(datas.expenses.evolution, true)) }}
                  </b-badge>
                </div>
                <b-card-text class="font-small-3 mb-0">
                  {{ capitalize($tc('expenses', datas.expenses.expenses)) }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

        </b-row>
      </b-overlay>

      <b-row v-if="selectedIndicatorView != ''">
        <b-col md="12">
          <div
              class="cursor-pointer"
              @click="$emit('update:selectedIndicatorView', '')"
          >
            <icon icon="arrow-alt-circle-left"/>
            Retour
          </div>
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch }                          from '@vue/composition-api'
import { isEmptyObject }                                 from '../../../../utils/utils'
import { capitalize, currency, displayNumberWithSymbol } from '../../../../utils/filter'

import SalesMainIndicatorButton         from './components/SalesMainIndicatorButton.vue'
import OpportunitiesMainIndicatorButton from './components/OpportunitiesMainIndicatorButton.vue'

export default {
  components: {
    SalesMainIndicatorButton,
    OpportunitiesMainIndicatorButton,
  },
  props: {
    datas: {
      type: Object,
      default: () => {}
    },
    selectedIndicatorView: {
      type: String
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !isEmptyObject(props.datas)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    const getBadgeColor = (number) => {
      return number > 0 ? 'light-success' : 'light-danger'
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      displayNumberWithSymbol,
      currency,
      capitalize,

      // Data

      // Computed
      isReady,

      // Methods
      getBadgeColor,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>