<template>
  <div>
    <b-card>
      <list-indicator
          :rows="rows"
          :list-entity-type="listEntityType"
      >

        <template #listView_cell_sourceIncomingRequest="{item}">
          <div v-if="item.row.sourceIncomingRequest != null">
            {{ item.row.sourceIncomingRequest }}
            <br/>
            <small>
              {{ item.row.sourceIncomingRequestSubDetails }}
            </small>
          </div>
        </template>

        <template #listView_cell_preTaxAmountSalesGenerated="{item}">
          {{ currency(item.row.preTaxAmountSalesGenerated, true) }}
        </template>

        <template #listView_cell_timeSpent="{item}">
          <div>
            {{ item.row.timeSpentUser + ' ' + $tc('hour', item.row.timeSpentUser) }}
          </div>
          <div>
            <small>{{ item.row.timeSpentGlobal + ' ' + $tc('hour', item.row.timeSpentGlobal) }} {{ $t('inTotal') }}
            </small>
          </div>
        </template>

        <template #listView_cell_team="{item}">
          <b-avatar-group size="32px">
            <b-avatar
                v-for="user in getUsersAvatars(item.row.team)"
                class="pull-up"
                v-b-tooltip.hover
                :title="user.title"
                :text="user.text"
                :src="user.avatar==null?false:user.avatar"
            />
          </b-avatar-group>
        </template>


      </list-indicator>
    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { currency, capitalize } from '../../../../../utils/filter'

import i18n   from '../../../../../libs/i18n'
import moment from 'moment'

import ListIndicator from '../components/ListIndicator.vue'
import { initials }  from '../../../../../utils/utils'

export default {
  components: {
    ListIndicator
  },
  props: {
    opportunitiesList: {
      type: Array,
      default: () => []
    },
    listEntityType: {
      type: String,
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const rows = computed(() => {
      let output = []

      if (props.listEntityType == 'offer') {
        props.opportunitiesList.forEach(o => {
          output.push({
            id: o.id,
            number: o.billNumber,
            billingDate: moment(o.billingDate).format('YYYY-MM-DD'),
            customer: o.customerCompany != null ? o.customerCompany._display : o.customerIndividual._display,
            title: o.title,
            preTaxAmount: o.preTaxAmount,
            totalAmount: o.totalAmount,
            balance: o._billable_preTaxAmount_balance,
          })
        })
      } else if (props.listEntityType == 'workFlo') {
        props.opportunitiesList.forEach(w => {
          output.push({
            id: w.id,
            company: w.company._display,
            status: w.status.name,
            title: w.title,
            typeOfPurchase: w.typeOfPurchase,
            sourceIncomingRequest: capitalize(i18n.tc(w.sourceIncomingRequest, 1)),
            sourceIncomingRequestSubDetails: getIncomingRequestSubDetails(w),
          })
        })
      } else if (props.listEntityType == 'customer') {
        props.opportunitiesList.forEach(w => {
          output.push({
            id: w.id,
            company: w.company._display,
            preTaxAmountSalesGenerated: w._preTaxAmountSalesGenerated,
            team: w._concernedPersons,
            timeSpentGlobal: w._timeSpentGlobal,
            timeSpentUser: w._timeSpentUser,
          })
        })
      }

      return output
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const getIncomingRequestSubDetails = (workFlo) => {
      switch (workFlo.sourceIncomingRequest) {
        case 'press':
          return workFlo.sourcePressMedia
        case 'social networking':
          return capitalize(i18n.t(workFlo.sourceSocialNetwork))
        case 'networks':
          return capitalize(i18n.t(workFlo.sourceNetwork))
        case 'event':
          return workFlo.sourceEvent
        default:
          return null
      }
    }

    const getUsersAvatars = (users) => {
      let output = []
      users.forEach(u => {
        output.push({
          title: u._display,
          text: initials(u._display),
          avatar:  u.avatar == null ? null : u.avatar.url
        })
      })

      return output
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      rows,
      // searchTerm,

      // Computed

      // Methods
      getUsersAvatars,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { currency, capitalize },
  mounted () {
  },
  created () {
  }
}
</script>


<style lang="scss">
@import '~@/assets/scss/vue/libs/vue-good-table';
</style>