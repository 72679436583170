<template>
  <div>
    <vue-apex-charts
        type="bar"
        height="400"
        :options="chartOptions"
        :series="series"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../../../../utils/filter'

import VueApexCharts from 'vue-apexcharts'

import i18n   from '../../../../../../libs/i18n'
import moment from 'moment'

export default {
  components: {
    VueApexCharts
  },
  props: {
    pipeForecastChart: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !(Object.keys(props.pipeForecastChart.chart).length === 0 && props.pipeForecastChart.chart.constructor === Object)
    })

    const months = computed(() => {
      return [
        capitalize(i18n.t('needs')),
        capitalize(i18n.tc('offer', 2)),
        capitalize(i18n.t('hotOffers')),
        capitalize(i18n.t('remainingOrders'))
      ]
    })

    const series = computed(() => {
      if (isReady.value) {
        console.log(props.pipeForecastChart)
        let users = Object.keys(props.pipeForecastChart.chart.Need)
        let output = []
        users.forEach(user => {
          output[user] = {
            need: 0,
            offer: 0,
            hotOffer: 0,
            remainingOrders: 0,
          }
        })

        Object.keys(props.pipeForecastChart.chart).forEach(key => {
          if (key == 'Need') {
            for (const user2 in props.pipeForecastChart.chart['Need']) {
              output[user2].need += props.pipeForecastChart.chart['Need'][user2]
            }
          } else if (key == 'Offer') {
            for (const user2 in props.pipeForecastChart.chart['Offer']) {
              output[user2].offer += props.pipeForecastChart.chart['Offer'][user2]
            }
          } else if (key == 'Hot offer') {
            for (const user2 in props.pipeForecastChart.chart['Hot offer']) {
              output[user2].hotOffer += props.pipeForecastChart.chart['Hot offer'][user2]
            }
          } else if (key == 'Remaining orders') {
            for (const user2 in props.pipeForecastChart.chart['Remaining orders']) {
              output[user2].remainingOrders += props.pipeForecastChart.chart['Remaining orders'][user2]
            }
          }
        })

        let output2 = []

        for (const user3 in output) {
          output2.push({
            name: user3,
            data: Object.values(output[user3])
          })
        }

        return output2
      } else {
        return []
      }
    })

    const chartOptions = computed(() => {
      return {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        // colors: ['#826af9', '#d2b0ff', '#f8d3ff'],
        plotOptions: {
          bar: {
            columnWidth: '20',
            borderRadius: 5,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: 'top',
          fontSize: '14px',
          fontFamily: 'Montserrat',
          horizontalAlign: 'left',
        },
        stroke: {
          show: true,
          colors: ['transparent'],
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: months.value,
          labels: {
            formatter: (val) => {
              return val
            }
          }
        },
        yaxis: {
          // opposite: isRtl,
          labels: {
            formatter: (val) => {
              return currency(val, true)
            }
          }
        },
        fill: {
          opacity: 1,
        },
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      series,
      chartOptions,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>