<template>
  <div>
    <b-overlay
        :show="!isReady"
        rounded="sm"
    >
      <div v-if="isReady">
        <div class="d-flex">
          <b class="my-auto">{{ capitalize($t('total hourly volume:')) }}</b>
          <b class="mx-2 my-auto">{{ opportunitiesTimeSpentChart.global['total hourly volume'].value }}
                                  {{ $tc('hour', opportunitiesTimeSpentChart.global['total hourly volume'].value) }}
          </b>
          <indicator-evolution
              :indicator="opportunitiesTimeSpentChart.global['total hourly volume'].evolution"
              class="my-auto"
          />
        </div>

        <div class="d-flex">
          <b class="my-auto">{{ capitalize($t('average time:')) }}</b>
          <b class="mx-2 my-auto">{{ opportunitiesTimeSpentChart.global['average time'].value }}
                                  {{ $tc('hour', opportunitiesTimeSpentChart.global['total hourly volume'].value) }}
          </b>
          <indicator-evolution
              :indicator="opportunitiesTimeSpentChart.global['average time'].evolution"
              class="my-auto"
          />
        </div>

        <vue-apex-charts
            type="bar"
            height="400"
            :options="chartOptions"
            :series="series"
            class="mt-3"
        />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../../../../utils/filter'

import moment from 'moment'

import VueApexCharts      from 'vue-apexcharts'
import { round }          from '@popperjs/core/lib/utils/math'
import i18n               from '../../../../../../libs/i18n'
import IndicatorEvolution from '../../../../../../components/indicatorEvolution/IndicatorEvolution.vue'
import VerticalIndicator  from '../../components/VerticalIndicator.vue'

export default {
  components: { VerticalIndicator, IndicatorEvolution, VueApexCharts },
  props: {
    opportunitiesTimeSpentChart: {
      type: Object,
      default: () => {}
    },
    opportunitiesTimeSpentRatios: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !(Object.keys(props.opportunitiesTimeSpentChart.chart).length === 0 && props.opportunitiesTimeSpentChart.chart.constructor === Object)
    })

    const users = computed(() => {
      let users = []

      for (const monthStr in props.opportunitiesTimeSpentChart.chart) {
        Object.keys(props.opportunitiesTimeSpentChart.chart[monthStr]).forEach(user => {
          if (!users.includes(user)) {
            users.push(user)
          }
        })
      }

      return users
    })

    const series = computed(() => {
      let series = []

      users.value.forEach(user => {
        series.push({
          name: user,
          data: getUserValues(user),
        })
      })

      return series
    })

    const months = computed(() => {
      if (isReady.value) {
        return Object.keys(props.opportunitiesTimeSpentChart.chart)
      } else {
        return []
      }
    })

    const chartOptions = computed(() => {
      return {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        // colors: ['#826af9', '#d2b0ff', '#f8d3ff'],
        plotOptions: {
          bar: {
            columnWidth: '20',
            borderRadius: 5,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: 'top',
          fontSize: '14px',
          fontFamily: 'Montserrat',
          horizontalAlign: 'left',
        },
        stroke: {
          show: true,
          colors: ['transparent'],
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: months.value,
          labels: {
            formatter: (val) => {
              return capitalize(moment(val).format('MMMM YYYY'))
            }
          }
        },
        yaxis: {
          // opposite: isRtl,
          labels: {
            formatter: (val) => {
              return round(val) + ' ' + i18n.tc('hour', val)
            }
          }
        },
        fill: {
          opacity: 1,
        },
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const getUserValues = (user) => {
      let output = []
      for (const monthStr in props.opportunitiesTimeSpentChart.chart) {
        if (user in props.opportunitiesTimeSpentChart.chart[monthStr]) {
          output.push(props.opportunitiesTimeSpentChart.chart[monthStr][user])
        } else {
          output.push(0)
        }
      }

      return output
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      isReady,
      series,
      chartOptions,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>