<template>
  <div>
    <b-overlay
        :show="!isReady"
        rounded="sm"
        class="w-100"
        bg-color="white"
    >
      <b-card-body
          v-if="isReady"
          class="d-flex justify-content-between align-items-center p-0"
      >

        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder">
            {{ value }}
          </h2>
          <span>
            {{ capitalize($t(title)) }}
          </span>
        </div>
        <b-avatar
            :variant="'light-'+color"
            size="45"
        >
          <icon
              :icon="icon"
              size="2x"
          />
        </b-avatar>
      </b-card-body>


      <b-card-body v-else>
      </b-card-body>
    </b-overlay>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../../../utils/filter'

export default {
  components: {},
  props: {
    isReady: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    value: {
      type: String
    },
    evolution: {
      type: Number
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>