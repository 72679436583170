<template>
  <div class="d-inline">
    <span
        v-if="indicator>0"
        class="text-success"
    >▲ {{ localIndicator }}
    </span>
    <span
        v-else-if="indicator<0"
        class="text-danger"
    >▼ {{ localIndicator }}
    </span>
    <span
        v-else
        class="text-warning"
    >= {{ localIndicator }}
    </span>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { percent }              from '../../utils/filter'

export default {
  components: {},
  props: {
    indicator: {
      type: Number,
      default: 0
    },
    isPercent: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const localIndicator = computed(() => {
      if (props.isPercent) {
        return percent(props.indicator, 0)
      } else {
        return props.indicator
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      localIndicator,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>