var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('list-indicator',{attrs:{"rows":_vm.rows,"list-entity-type":_vm.listEntityType},scopedSlots:_vm._u([{key:"listView_cell_sourceIncomingRequest",fn:function(ref){
var item = ref.item;
return [(item.row.sourceIncomingRequest != null)?_c('div',[_vm._v(" "+_vm._s(item.row.sourceIncomingRequest)+" "),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.row.sourceIncomingRequestSubDetails)+" ")])]):_vm._e()]}},{key:"listView_cell_preTaxAmountSalesGenerated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currency(item.row.preTaxAmountSalesGenerated, true))+" ")]}},{key:"listView_cell_timeSpent",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.row.timeSpentUser + ' ' + _vm.$tc('hour', item.row.timeSpentUser))+" ")]),_c('div',[_c('small',[_vm._v(_vm._s(item.row.timeSpentGlobal + ' ' + _vm.$tc('hour', item.row.timeSpentGlobal))+" "+_vm._s(_vm.$t('inTotal'))+" ")])])]}},{key:"listView_cell_team",fn:function(ref){
var item = ref.item;
return [_c('b-avatar-group',{attrs:{"size":"32px"}},_vm._l((_vm.getUsersAvatars(item.row.team)),function(user){return _c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"pull-up",attrs:{"title":user.title,"text":user.text,"src":user.avatar==null?false:user.avatar}})}),1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }