<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <b-form-input
              :model="searchTerm"
              :placeholder="capitalize($t('search'))"
              type="text"
              class="d-inline-block"

              @input="searchTerm = $event"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="searchOptions"
        :select-options="selectOptions"
        :pagination-options="paginationOptions"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >

        <slot
            :name="'listView_cell_'+props.column.field"
            :item="props"
        >
          {{ props.formattedRow[props.column.field] }}
        </slot>

      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-center flex-wrap">
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <icon icon="chevron-left"/>
              </template>
              <template #next-text>
                <icon icon="chevron-right"/>
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { VueGoodTable }         from 'vue-good-table'
import { capitalize }           from '../../../../../utils/filter'
import i18n                     from '../../../../../libs/i18n'

export default {
  components: {
    VueGoodTable
  },
  props: {
    rows: {
      type: Array,
      default: () => []
    },
    pageLength: {
      type: Number,
      default: 20
    },
    listEntityType: {
      type: String,
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const searchTerm = ref('')
    const searchOptions = ref({
      enabled: true,
      externalQuery: searchTerm.value
    })
    const selectOptions = ref({
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    })
    const paginationOptions = ref({
      enabled: true,
      perPage: props.pageLength
    })
    const offerColumns = ref([
      {
        label: capitalize(i18n.t('id')),
        field: 'id',
        type: 'number'
      },
      {
        label: capitalize(i18n.t('offerNumber')),
        field: 'number'
      },
      {
        label: capitalize(i18n.t('billingDate')),
        field: 'billingDate',
        type: 'date',
        dateInputFormat: 'yyyy-MM-dd',
        dateOutputFormat: 'dd/MM/yyyy'
      },
      {
        label: capitalize(i18n.t('customer')),
        field: 'customer'
      },
      {
        label: capitalize(i18n.t('title')),
        field: 'title'
      },
      {
        label: capitalize(i18n.t('preTaxAmount')),
        field: 'preTaxAmount'
      },
      {
        label: capitalize(i18n.t('totalAmount')),
        field: 'totalAmount'
      },
      {
        label: capitalize(i18n.t('balance')),
        field: 'balance'
      }
    ])
    const workFloColumns = ref([
      {
        label: capitalize(i18n.t('id')),
        field: 'id',
        type: 'number'
      },
      {
        label: capitalize(i18n.tc('company', 1)),
        field: 'company'
      },
      {
        label: capitalize(i18n.t('status')),
        field: 'status',
      },
      {
        label: capitalize(i18n.t('title')),
        field: 'title'
      },
      {
        label: capitalize(i18n.t('typeOfPurchase')),
        field: 'typeOfPurchase'
      },
      {
        label: capitalize(i18n.t('sourceIncomingRequest')),
        field: 'sourceIncomingRequest'
      },
      {
        label: capitalize(i18n.t('sourceDetails')),
        field: 'sourceDetails'
      }
    ])
    const customerColumns = ref([
      {
        label: capitalize(i18n.t('id')),
        field: 'id',
        type: 'number'
      },
      {
        label: capitalize(i18n.tc('company', 1)),
        field: 'company'
      },
      {
        label: capitalize(i18n.tc('preTaxAmountSalesGenerated', 1)),
        field: 'preTaxAmountSalesGenerated',
        type: 'number'
      },
      {
        label: capitalize(i18n.t('team')),
        field: 'team',
      },
      {
        label: capitalize(i18n.t('timeSpent')),
        field: 'timeSpent',
        type: 'number'
      },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const columns = computed(() => {
      if (props.listEntityType == 'offer') {
        return offerColumns.value
      } else if (props.listEntityType == 'workFlo') {
        return workFloColumns.value
      } else if (props.listEntityType == 'customer') {
        return customerColumns.value
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(searchTerm, val => {
      searchOptions.value.externalQuery = val
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      searchOptions,
      selectOptions,
      paginationOptions,
      searchTerm,

      // Computed
      columns,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>


<style lang="scss">
@import '~@/assets/scss/vue/libs/vue-good-table';
</style>